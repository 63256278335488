import React from "react";
import {useTranslation} from "react-i18next";
import {useFormatString} from "../components/Utils/formatString";
import PublicBanner from './elements/PublicBanner';

function Faq() {
    const {t} = useTranslation(['translation']);
    const formatString = useFormatString();
    const paragraph1 = formatString('frequently asked questions.paragraph-1');
    const paragraph2  = formatString('frequently asked questions.paragraph-2');
    const paragraph2_1 = formatString('frequently asked questions.paragraph-2_1');

    return (
        <>
            <PublicBanner title="frequently asked questions.title-1"/>
            
            <section className="w3l-aboutblock1 py-3" id="privacy-policy">
                <div className="container">
                    <div className="row">
                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.what is your tu recargas")}
                        </h3>

                        <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph1}}></p>

                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.how to send top-ups online")}
                        </h3>

                        <p className="mb-4 w-100" dangerouslySetInnerHTML={{__html: paragraph2}}></p>
                        <p className="mb-4 w-100" dangerouslySetInnerHTML={{__html: paragraph2_1}}></p>


                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.how long will it take to receive my top-up")}
                        </h3>

                        <p className="mb-4">{t("frequently asked questions.top up a phone with us you choose the time as you can choose instant top ups or 2 hour waiting time top ups")}</p>

                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.can I top up a phone other than my own")}
                        </h3>

                        <p className="mb-4">{t("frequently asked questions.you simply need the mobile number you wish to send the money")}</p>

                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.will top-up arrive if the phone is roaming")}
                        </h3>

                        <p className="mb-4">{t("frequently asked questions.the recharge will be delivered directly to the line, regardless of its location")}</p>

                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.how to log in with a single-use code")}
                        </h3>

                        <p className="mb-4">{t("frequently asked questions.you can now log in to your registered")}</p>

                        <h3 className="font-weight-bold mb-3">
                            {t("frequently asked questions.where is my card data stored")}
                        </h3>

                        <p className="mb-5">{t("frequently asked questions.for security reasons, we do not handle payments directly")}</p>


                    </div>
                </div>
            </section>

        </>
    );
}

export default Faq;