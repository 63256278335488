import React, { useEffect, useContext } from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../../components/Common/Loading';
import { AuthContext } from '../../components/Auth/AuthContext';

const ProtectedRoute = () => {
    const { token, loading } = useContext(AuthContext);
    useEffect(() => {
        document.body.className = 'account';
        if (loading) document.getElementById('root').style.display='none';
        return () => { document.body.className = ''; }
    });
    if (loading) {
        return <Loading/>
    }
    if (!token) {
        return <Navigate to="/signin" />;
    }
    return <Outlet />;
};

export default ProtectedRoute;
