const jsHelper = {
    scrollToTop: () =>{
        window.scrollTo(0,0);
    },
    clearClientStorage: () => {
        localStorage.removeItem('from');
        localStorage.removeItem('prefixObj');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('providerObj');
    },
    patterPhoneNumberToSend: (prefix,phoneNumber) =>{ console.log('patterPhoneNumberToSend', prefix,phoneNumber);
        const plus = (prefix && !String(prefix).startsWith('+')) ? '+' : '';
        const _pn =  String(phoneNumber).replace(/ /g, '');
        const r = plus + prefix + _pn;
        return r;
    },
    getPhoneNumberWithOutPrefix: (prefix,phoneNumber) =>{
        const plus = (prefix && !prefix.startsWith('+')) ? '+' : '';
        const _pn =  String(phoneNumber).replace(/ /g, '');
        const r = String(_pn).replace(plus+prefix,'');
        return r;
    }

}
export default jsHelper;