import React from 'react';
import PrivateBanner from './elements/PrivateBanner';

const NotFound = () => {
    return (
        <>
            <PrivateBanner title="  "/>
            <section className="w3l-aboutblock1 py-3" id="page-404">
                <img src='/404.jpg' alt="404 Error"/>
            </section>

        </>
    );
};

export default NotFound;
