import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../views/auth/protectedRoute';
import Account from '../views/client/account';
import Logout from '../components/Auth/Logout';
import History from '../views/client/history';
import Beneficiaries from '../views/client/beneficiaries';
import NotFound from '../views/notFound'; // Importa la página 404

const ProtectedRoutes = () => {
    return (
        <Routes>
            <Route path="/*" element={<ProtectedRoute />}>
                <Route path="account" element={<Account />} />
                <Route path="history" element={<History />} />
                <Route path="beneficiaries" element={<Beneficiaries />} />
                <Route path="logout" element={<Logout />} />
                {/* Ruta para página 404 dentro de las rutas protegidas */}
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default ProtectedRoutes;

// }