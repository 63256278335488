import React from 'react';
import { useTranslation } from 'react-i18next';
import PublicBanner from './elements/PublicBanner';
import env from "../helper/env.js";

function Home() {
    const { t } = useTranslation();
    const operators = [
        {id:1, src: env.img_basepath + "icons-operator/icon-digi.gif", alt:"digi"},
        {id:2, src: env.img_basepath + "icons-operator/icon-digitel.webp", alt:"digitel"},
        {id:3, src: env.img_basepath + "icons-operator/icon-lebara.webp", alt:"lebara"},
        {id:4, src: env.img_basepath + "icons-operator/icon-masmovil.webp", alt:"masmovil"},
        {id:5, src: env.img_basepath + "icons-operator/icon-movistar.webp", alt:"movistar"},
        {id:6, src: env.img_basepath + "icons-operator/icon-orange.webp", alt:"orange"},
        {id:7, src: env.img_basepath + "icons-operator/icon-simyo.webp", alt:"simyo"},
        {id:8, src: env.img_basepath + "icons-operator/icon-suop.webp", alt:"suop"},
        {id:9, src: env.img_basepath + "icons-operator/icon-vodafone.webp", alt:"vodafone"},
        {id:10,src: env.img_basepath + "icons-operator/icon-yoigo.webp", alt:"yoigo"},
        {id:11,src: env.img_basepath + "icons-operator/icon-you.webp", alt:"you"},
    ];
    return (<>
        <PublicBanner
            title = "home.Recharge domestic and international mobiles"
            subtitle = "home.it has never been so easy"
            paragraph = "home.Recharge your mobile phone anytime, anywhere, regardless of carrier"
            link = "recharge" linkText = "Ready to send a top-up?"
            linkClass="btn btn-style btn-primary mt-sm-5 mt-4 mr-2"
        />

            <section className='home'>
                <div className="customer-layout py-5">
                    <div className="container py-lg-4 py-md-3 pb-lg-0">
                        <h3 className="title-big text-center">{t("home.how does it work")}</h3>
                        <h5 className="lead text-center mb-5 mt-2">{t("home.send a top-up in easy steps")}.</h5>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="featured-box style-4">
                                    <div className="featured-box-icon bg-light-2 text-secondary shadow-sm rounded-circle">
                                        <i className="fa fa-mobile" aria-hidden="true"></i>
                                    </div>
                                    <h3 className="text-5 fw-400 mb-3">{t("home.enter mobile number")}</h3>
                                    <p className="text-3">{t("home.summary fast 1")}.</p>
                                </div>
                            </div>
                            <div className="col-md-4 pt-4 pb-4">
                                <div className="featured-box style-4">
                                    <div className="featured-box-icon bg-light-2 text-secondary shadow-sm rounded-circle">
                                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                                    </div>
                                    <h3 className="text-5 fw-400 mb-3">{t("home.select top-up amount")}</h3>
                                    <p className="text-3">{t("home.summary fast 2")}.</p>
                                </div>
                            </div>
                            <div className="col-md-4 pt-4 pb-4">
                                <div className="featured-box style-4">
                                    <div className="featured-box-icon bg-light-2 text-secondary shadow-sm rounded-circle">
                                        <i className="fa fa-money" aria-hidden="true"></i>
                                    </div>
                                    <h3 className="text-5 fw-400 mb-3">{t("home.confirm your payment")}</h3>
                                    <p className="text-3">{t("home.summary fast 1")}.</p>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <a className="btn btn-style btn-outline-secondary mt-sm-5 mt-4 mr-2 " href="recharge">
                                    {t("home.top-up now")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w3l-testimonials py-5 home">
                <div className="call-w3 py-md-4 py-2">
                    <h3 className="title-big text-center mb-sm-5 mb-4">{t("home.we work with more than 40 operators from all over the world")}</h3>
                    <div className="container">
                        <div className="company-logos text-center">
                            <div className="row logos">
                                {operators.map(item => (
                                    <div key={item.id} className="col-6 col-lg-3 mb-4">
                                        <img src={item.src} alt={item.alt} className="img-fluid"/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home