import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import callServerApi from "./Utils/apiManager";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import ResponseAlert from "./Common/ResponseAlert";
import { Loading } from './Common/Loading';

import 'react-phone-number-input/style.css';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: false,
            typeSubject: [],
            responseError: '',
            responseSuccess: '',
            formData : {
                typeSubjectId: 1,
                name: '',
                phone: '',
                email: '',
                message: '',
            },
            errors: {},
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    componentDidMount = () =>{
        const { i18n } = this.props;
        const fnNext = (result) => {
            if (result.status ==='ok' && result.data){
                if (result.data.length> 0) result.data[0].isValid = true;
                this.setState({
                    loading:false,
                    typeSubject: result.data
                });
            }else{
                this.setState({
                    message: result.error,
                    loading:false
                });
                setTimeout(() =>{ this.setState({message:''})}, 5000);
            }
        }
        callServerApi('contact/type-subjects', 'GET', {locale: i18n.language})
            .then(fnNext)
            .catch(error => {
                this.setState({
                    message: error.message,
                    loading: false
                });
                setTimeout(() => { this.setState({message: ''})}, 5000);
            });
    }

    handleChange(event) {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        });
    }
    handleSubjectChange(id) {
        let d = this.state.formData;
        d.typeSubjectId = id;
        this.setState({ formData: d });

        let t = this.state.typeSubject;
        //const found = array1.find((element) => element > 10);
        for (let i = 0; i < t.length; i++) {
           if  (Number(t[i].id) === Number(id)) t[i].isValid = true;
           else if (t[i].isValid) delete t[i].isValid;
        }
        this.setState({ typeSubject: t });
    }

    handlePhoneChange(value) {
        let d = this.state.formData;
        d.phone = value;
        this.setState({ formData: d });
    }

    validateForm() {
        let isValid = true;
        let errors = { ...this.state.errors };

        if (!this.state.formData.typeSubjectId) {
            isValid = false;
            errors.typeSubjectId = 'form.required';
        }else{
            errors.typeSubjectId = '';
        }

        const validators = {
            name: /\S/,
            phone: /^\+[1-9]{1}[0-9]{4,13}$/,
            email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            message: /\S/
        };

        Object.keys(this.state.formData).forEach(name => {
            if(validators[name]) {
                if (!this.state.formData[name]) {
                    isValid = false;
                    errors[name] = 'form.required';
                } else if(!validators[name].test(this.state.formData[name])) {
                    isValid = false;
                    errors[name] = 'form.invalidField';
                } else {
                    errors[name] = '';
                }
            }
        });
        this.setState({ errors });
        return isValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()){
            this.setState({loading:true});
            const fnNextForm = (result) => {
                if (result.status ==='ok' && result.data){
                    this.setState({
                        loading:false,
                        responseSuccess: result.message,
                        formData: {
                            subjectConctact: 1,
                            name: '',
                            phone: '',
                            email: '',
                            message: ''
                        },

                    });
                    this.formRef.current.reset();
                }
                setTimeout(() =>{ this.setState({responseSuccess:''})}, 5000);
            }
            callServerApi('contact/new-message', 'POST', this.state.formData)
                .then(fnNextForm)
                .catch(error => {
                    this.setState({
                        responseError: error.data.error,
                        loading: false,
                    });
                    setTimeout(() => { this.setState({responseError: ''})}, 5000);
                });
        }
    }

    renderResponse(response, alertClass) {
        if (response) {
            return (
                <div className={alertClass} role="alert">
                    {response}
                </div>
            )
        }
    }

    render() {
        const { loading, formData, typeSubject, responseSuccess,responseError, errors} = this.state;
        return (
            <Translation ns={['translation']}>
                { (t) => <div  className='map-content-9'>
                    {loading && <Loading/>}
                    <div className='container'>


                            {responseSuccess && <ResponseAlert response={responseSuccess} variant="success" />}
                            {responseError && <ResponseAlert response={responseError} variant="danger" />}

                            <form className="mt-1" id="form-contact" onSubmit={this.handleSubmit} ref={this.formRef}>
                                <InputGroup className='group-radio' >
                                {typeSubject.map(item=> (
                                    <Form.Check key={item.id} type="radio" id={`typeSubjectId-${item.id}`} >
                                        <Form.Check.Input type="radio" value={item.id} className="form-control" isValid={item.isValid} />
                                        <Form.Check.Label onClick={()=> this.handleSubjectChange(item.id)}>{item.name}</Form.Check.Label>

                                    </Form.Check>
                                ))}
                                {errors && errors.typeSubjectId && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{t(errors.typeSubjectId)} </Form.Control.Feedback>}


                                </InputGroup>
                                <InputGroup>
                                    <Form.Control  className="last-child" name="name" autoFocus
                                        placeholder={t('contact.name')}
                                        required
                                        onChange={this.handleChange}
                                    />
                                    {errors && errors.name && <Form.Control.Feedback type="invalid"  style={{display: 'block'}}>{t(errors.name)} </Form.Control.Feedback>}
                                </InputGroup>

                                <InputGroup>
                                    <PhoneInput
                                        flags={flags}
                                        placeholder={t('form.enter phone number')}
                                        name="phone" value={formData.phone}
                                        id="phoneContact"
                                        onChange={this.handlePhoneChange}
                                        className="form-control"
                                        defaultCountry="ES"
                                        international
                                    />
                                    {errors && errors.phone && <Form.Control.Feedback type="invalid"
                                                                                        style={{display: 'block'}}>{t(errors.phone)} </Form.Control.Feedback>}
                                </InputGroup>
                                <InputGroup className="one-child">
                                    <Form.Control name="email"
                                        placeholder={t('contact.email')}
                                        id="emailContact"
                                        onChange={this.handleChange}
                                    />
                                    {errors && errors.email && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.email)} </Form.Control.Feedback> }
                                </InputGroup>

                                <InputGroup className="one-child">
                                    <Form.Control as="textarea" rows={6}
                                        name="message"
                                        id="messageContact"
                                        placeholder={t('contact.your query')}
                                        onChange={this.handleChange}/>
                                    {errors && errors.message && <Form.Control.Feedback type="invalid"
                                                                                        style={{display: 'block'}}>{t(errors.message)} </Form.Control.Feedback>}
                                </InputGroup>
                                <div className="text-right">
                                    <button type="submit"
                                            className="btn btn-primary btn-style mt-4">
                                        {t('contact.send message')}
                                    </button>
                                </div>
                            </form>
                    </div>
                </div>
                }
            </Translation>
        )
    }
}

export default withTranslation()(Contact);