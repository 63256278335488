import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/Auth/AuthContext";
import Header from "./views/elements/Header";
import Footer from "./views/elements/Footer";
import Home from "./views/home";
import Recharge from "./views/recharge";
import About from "./views/about";
import LegalNotice from "./views/legal-notice";
import PrivacyPolicy from "./views/privacy-policy";
import CookiePolicy from "./views/cookie-policy";
import Contact from "./views/contact";
import Signup from "./views/auth/register";
import Signin from "./views/auth/login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Faq from "./views/frequently-asked-questions";
import TermsConditions from "./views/terms-conditions";
import NotFound from "./views/notFound"; // Importa la página 404

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route exact="true" path="/recharge" element={<Recharge />} />
                    <Route exact="true" path="/about" element={<About />} />
                    <Route exact="true" path='/legal-notice' element={<LegalNotice/>} />
                    <Route exact="true" path='/privacy-policy' element={<PrivacyPolicy/>} />
                    <Route exact="true" path='/terms-conditions' element={<TermsConditions/>} />
                    <Route exact="true" path='/cookie-policy' element={<CookiePolicy/>} />
                    <Route exact="true" path='/frequently-asked-questions' element={<Faq/>} />
                    <Route exact="true" path="/contact" element={<Contact />} />
                    <Route exact="true" path="/signup" element={<Signup />} />
                    <Route exact="true" path="/signin" element={<Signin />} />

                    {/* Rutas protegidas */}
                    <Route path="/*" element={<ProtectedRoutes />} />

                    {/* Ruta para la página 404 */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
