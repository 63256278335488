import React from "react";
import {useTranslation} from 'react-i18next';
import PublicBanner from './elements/PublicBanner';
import env from "../helper/env.js";

export default function About(props) {
    const {t} = useTranslation(['translation']);
    return (
        <>
            
            <PublicBanner title ="About us.name"/>

            <section className="w3l-aboutblock1 py-5" id="about">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="row">
                        <div className="col-lg-6 align-self">
                            <span className="title-small mb-2">{t("About us.name")}</span>
                            <h3 className="title-big mx-0">{t("About us.name")}</h3>
                            <p className="mt-lg-4 mt-3 text-justify">{t("paragraph about")}</p>
                            <p className="mt-3 mb-lg-5"></p>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6 pl-0  mb-4">
                                        <ul className="ul-about">
                                            <li className="mb-4">
                                                <span>
                                                  <img src={env.img_basepath + "100-secure.png"} alt="100-secure"/>
                                                </span>
                                                <span className="pl-2">{t("About us.100% Secure")}</span>
                                            </li>
                                            <li>
                                                <span>
                                                  <img src={env.img_basepath + "refer-earn.png"} alt="refer-earn"/>
                                                </span>
                                                <span className="pl-2">{t("About us.Refer & Earn")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6  pl-0">
                                        <ul className="ul-about">
                                            <li className="mb-4">
                                                <span>
                                                  <img src={env.img_basepath + "trust-pay.png"} alt="trust-pay"/>
                                                </span>
                                                <span className="pl-2">{t("About us.trust pay")}</span>
                                            </li>
                                            <li>
                                                <span>
                                                  <img src={env.img_basepath + "24-support.png"} alt="24h-support"/>
                                                </span>
                                                <span className="pl-2">{t("About us.24X7 Support")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 left-wthree-img mt-lg-0 mt-sm-5 mt-4">
                            <img src={env.img_basepath + "about.png"} alt={t("About us.name")} className="img-fluid radius-image"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

