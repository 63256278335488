import React from "react";
import {useTranslation} from "react-i18next";
import env from "../../helper/env.js";
import CookieConsent from "react-cookie-consent";

function Footer() {
    const { t } = useTranslation();
    const companyName = env.name_company;

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    return (
        <div>
            {/* footer */}
            <section className="w3l-footer-29-main">
                <div className="footer-29 py-5">
                    <div className="container py-md-4">
                        <div className="row footer-top-29">
                            <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
                                <h6 className="footer-title-29">{t("Contact Info")}</h6>
                                <p>
                                    {t("Address")} :  {t("footer.address")}
                                </p>
                                <p className="my-2">
                                    {t("Phone")} : <a href={"tel:"+ t("footer.tel")}>{ t("footer.tel")}</a>
                                </p>
                                <p className="my-2">
                                    WhatsApp : <a href={t("footer.whatsAppLink")}>{ t("footer.tel")}</a>
                                </p>
                                <p>
                                    {t("Email")} : <a href={"mailto:"+t("footer.email")}>{ t("footer.email")}</a>
                                </p>
                                <div className="main-social-footer-29 mt-4">
                                    <a href="#facebook" className="facebook">
                                        <span className="fa fa-facebook"></span>
                                    </a>
                                    <a href="#instagram" className="instagram">
                                        <span className="fa fa-instagram"></span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">
                                <ul>
                                    <h6 className="footer-title-29">{t("About us.name")}</h6>
                                    <li>
                                        <a href="about">{t("Our company")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                                <h6 className="footer-title-29">{t("Information legal")}</h6>
                                <ul>
                                    <li>
                                        <a href="/legal-notice">{t("legal notice.title")}</a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy">{t("Privacy policy")}</a>
                                    </li>
                                    <li>
                                        <a href="/terms-conditions">{t("Terms Conditions")}</a>
                                    </li>
                                    <li>
                                        <a href="/cookie-policy">{t("Cookies policy")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-7 footer-list-29 footer-4 mt-lg-0 mt-5">
                                <h6 className="footer-title-29">{t("Help")}</h6>
                                <a href="/contact">
                                    {t("Contact")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* copyright */}
                <section className="w3l-copyright text-center">
                    <div className="container">
                        <p className="copy-footer-29">
                            © 2024 . <strong className="text-white">{companyName}</strong> All rights reserved.
                        </p>
                    </div>

                    {/* move top */}
                    <button
                        onClick={topFunction}
                        id="movetop"
                        title="Go to top"
                        aria-label="Go to top"
                        className="btn-go-top-up">
                         ⤴
                    </button>
                    {/* /move top */}
                </section>
            </section>
            {/* //footer */}

            <CookieConsent
                location="bottom"
                buttonText= {t("cookieConsent.buttonText")}
                cookieName="CookieConsent"
                expires={150}
                enableDeclineButton
                declineButtonText = {t("cookieConsent.declineButtonText")}
                >
                <span dangerouslySetInnerHTML={{__html: t("cookieConsent.info")}}></span>
               
            </CookieConsent>
        </div>
    );
}

export default Footer;