import React from "react";
import {useTranslation} from "react-i18next";
import {useFormatString} from "../components/Utils/formatString";
import PublicBanner from './elements/PublicBanner';

function PrivacyPolicy() {
    const {t} = useTranslation(['translation']);
    const formatString = useFormatString();


    const paragraph1 = formatString('privacy-policy.paragraph-1');
    const paragraph2 = formatString('privacy-policy.paragraph-2');
    const paragraph3 = formatString('privacy-policy.paragraph-3');
    const paragraph4 = formatString('privacy-policy.paragraph-4');
    const paragraph5 = formatString('privacy-policy.paragraph-5');
    const paragraph6 = t('privacy-policy.paragraph-6');
    const paragraph7 = t('privacy-policy.paragraph-7');
    const paragraph8 = formatString('privacy-policy.paragraph-8');

    return (
        <>
            <PublicBanner title="privacy-policy.title"/>
            
            <section className="w3l-aboutblock1 py-3" id="privacy-policy">
                <div className="container">
                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.declaration privacy")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph1}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.what data is collected")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph2}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.why data is collected")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph3}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.how long do we retain your personal data")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph4}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.cookies")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph5}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.your rights")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph6}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.questions")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph7}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("privacy-policy.changes to this privacy policy")}
                    </h3>

                    <p className="mb-5" dangerouslySetInnerHTML={{__html: paragraph8}}></p>

                </div>
            </section>

        </>
    );
}

export default PrivacyPolicy;