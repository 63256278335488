import React from 'react';
import { Translation } from 'react-i18next';
import callServerApi from './apiManager.js';
import env from "../../helper/env.js";
import helper from "../../helper/fn.js";
import { Button, Nav, NavDropdown } from 'react-bootstrap';
import { Loading } from '../Common/Loading.js';
import ResponseAlert from '../Common/ResponseAlert.js';


class autocompletePhoneNumber extends React.Component {
  constructor(props){
      super(props);

      this.state = {
        loading:true, responseError: '',
        placeHolder:props.placeholder,
        isOpen:false, showAll:false,
        validError:'',
        btnTitle:props.btnTitle,btnTitleProps:props.btnTitle,
        inputprefixValue:'', inputphoneValue:'', inputphoneSelectedText: '', inputphoneStyle: {},
        prefixes: [],
        prefixesFiltered: [],
        prefixSelected: null,providerSelected: null,
        requiredConfirm: false,
        selectedIndex: -1,
        resetStorage: props.resetStorage,
        onChangeTitle: props.onChangeTitle || null
      };
      this.sendResult =  props.handleResult.bind(this);
      this.onResetPhoneNumber = (props.onResetPhoneNumber) ? props.onResetPhoneNumber.bind(this) : null
      this.onChangeFunction = (props.onChangeFunction) ? props.onChangeFunction.bind(this) : null
      this.inputprefix = React.createRef();
      this.inputphone = React.createRef();
      this.ulRef = React.createRef(); // Ref para el contenedor ul
      this.liRefs = []; // Array de refs para los elementos li
  }

  componentDidMount(){
    callServerApi('telephone-prefixes', 'GET')
    .then(result => {

      if (result.status === 'ok') {
          if (result && result.data && result.data.length > 0){
            const autocompleCompleteOptionsTmp = [];
            result.data.map(function(r){
                autocompleCompleteOptionsTmp.push({
                    img: env.img_basepath + r.img_flag,
                    label:r.country_name,
                    rlabel:`+${r.prefix}`,
                    value:`+${r.prefix}`,
                    max_length:r.max_length,
                    min_length:r.min_length,
                    country_iso: r.country_iso
                });
                return r;
            });
            autocompleCompleteOptionsTmp.sort((a, b) => (a.label > b.label ? 1 : -1));
            this.setState({prefixes : autocompleCompleteOptionsTmp});
            setTimeout(this.handleFocusInputPrefix, 500);
            if (!this.state.resetStorage) this.getStorageData();
          }
      } else {
        this.handleError(result.error);
      }
      this.setState({loading: false});
    })
    .catch(error => {
      this.handleError(error.message);
    });
  }
  componentWillUnmount() {
    console.log("Component will unmount");
    // Your cleanup code here
  }
  componentDidUpdate(prevProps, prevState) {
    // set focus
    if (this.state.inputprefixValue && this.state.inputprefixValue !== prevState.inputprefixValue && this.inputphone && this.inputphone.current) {
        this.inputphone.current.focus();
    }
  }
  handleError(message) {
    this.setState({ responseError: message || '', loading: false });
    //setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
  }
  getStorageData = () =>{
     // *localstorage
     try {
      const phoneNumber = localStorage.getItem('phoneNumber') || '';
      const prefixObjString = localStorage.getItem('prefixObj');
      const providerObjString = localStorage.getItem('providerObj');
      const prefixObj = (prefixObjString) ? JSON.parse(prefixObjString):null;
      const providerObj = (providerObjString) ? JSON.parse(providerObjString):null;

      if (prefixObj)
        this.setPrefix(prefixObj);
      if (phoneNumber)
        this.setPhoneNumber(phoneNumber);

      if (prefixObj && phoneNumber && providerObj){
          this.sendingResultData({
            prefixSelected:prefixObj,
            phoneNumber: phoneNumber,
            providerSelected:providerObj
          });
      }else if (prefixObj && phoneNumber && !providerObj){
          this.setState({requiredConfirm:true});
          setTimeout(() => { this.validPhoneNumber(); }, 1000);

      }else{
        this.setState({loading : false});
        if (this.inputprefix && this.inputprefix.current) {
          this.inputprefix.current.focus();
        }
      }
    }catch(err) {
      console.log('getStorageData error',err.message)
    }
  }
  reload =() =>{
    this.setState({
      inputprefixValue:'',
      inputphoneValue: '',
      prefixesFiltered: [],
      prefixSelected: null,
      isOpen: true, showAll:true
    });
    helper.clearClientStorage();
    if (this.onResetPhoneNumber) this.onResetPhoneNumber();

  }
  /**PREFIX SELECTION */
  handleFocusInputPrefix = () =>{
    if (!this.state.inputprefixValue) {
        this.setState({showAll:true, isOpen:true, prefixesFiltered: this.state.prefixes});
    }
  }

  handleChangeInputPrefix = (e) => {
    const inputprefixValue = e.target.value;
    const { prefixes } = this.state;
    this.setState({inputprefixValue: inputprefixValue, btnTitle: this.state.btnTitleProps, selectedIndex: -1 });

    const filtered = prefixes.filter(x =>
      x.label.toLowerCase().includes(inputprefixValue.toLowerCase()) || x.rlabel.includes(inputprefixValue) || inputprefixValue.includes(x.rlabel)
    );
    // console.log(inputprefixValue, filtered, prefixes);
    if (filtered && filtered.length === 1){
      this.setPrefix(filtered[0]);

    }else{
      this.setState({ prefixesFiltered: filtered, isOpen: true});
    }
  }

  handleKeyDownInputPrefix = (e) => {
    const { selectedIndex, prefixesFiltered } = this.state;
    //const pressedKey = e.key.toUpperCase();
    let nextIndex = 0;
 //   console.log(pressedKey, nextIndex, prefixes[0]);
    //e.preventDefault();
    switch (e.key) {
      case 'Enter':
        if (selectedIndex >= 0 && selectedIndex < prefixesFiltered.length) {
          this.setPrefix(prefixesFiltered[selectedIndex]);
        }
      break;
      case  'ArrowDown':
        nextIndex = (selectedIndex + 1) % prefixesFiltered.length;
        this.setState({ selectedIndex: nextIndex }, () => {
            this.scrollToItem(nextIndex);
        });
      break;
      case  'ArrowUp':
        nextIndex = (selectedIndex - 1 + prefixesFiltered.length) % prefixesFiltered.length;
        this.setState({ selectedIndex: nextIndex }, () => {
            this.scrollToItem(nextIndex);
        });
      break;
      default:
        break;
      //   // Filtrar por tecla presionada, que comience con esa letra

      //   nextIndex = prefixes.findIndex( //str.slice(1)
      //     (item) => item.label.toUpperCase().startsWith(pressedKey) || item.value.startsWith(pressedKey)
      //   );

      //   if (nextIndex !== -1) {
      //     this.setState({ selectedIndex: nextIndex }, () => {
      //         this.scrollToItem(nextIndex);
      //     });
      //   }
      //   break;
    }
  }

  handleLostFocusInputPrefix = () => {}

  setPrefix = (prefix) =>{
    if (!prefix.rlabel) prefix.rlabel = prefix.prefix;
    if (!prefix.img) prefix.img = env.img_basepath + prefix.img_flag;

    const pl = 60 + (prefix.rlabel.length);

    this.setState({
      showAll:false, isOpen:false,
      inputprefixValue: prefix.value,
      prefixSelected: prefix,
      selectedIndex: -1,
      inputphoneStyle:{paddingLeft:`${pl}px`}
    });

  }

   /**PHONE NUMBER */
  handleChangeInputPhone = (e) =>{
    const inputphoneValue = e.target.value;
    const v = inputphoneValue.replaceAll(' ', '');
    const isNumber = !isNaN(v);
    if (isNumber || !inputphoneValue) {
      this.setPhoneNumber(inputphoneValue);
    }
  }
  handleSelectInputPhone = (e) => {
    const input = e.target;
    const inputphoneSelectedText = input.value.substring(input.selectionStart, input.selectionEnd);
    this.setState({ inputphoneSelectedText });
  }

  handleKeyDownInputPhone = (e) => {
    const { inputphoneSelectedText,inputphoneValue } = this.state;
    if (e.key === 'Backspace' ) {
      if (inputphoneSelectedText) {
        const v = inputphoneValue.replace(inputphoneSelectedText,'');
        this.setState({ inputphoneValue: v, inputphoneSelectedText:'' });
      }else if(inputphoneValue === ''){
        this.reload();
      }
    }else if (e.key === 'Enter') {
      e.preventDefault();
      this.validPhoneNumber();
    }
  }
  setPhoneNumber = (number) =>{
    const btnTitle = (this.onChangeTitle ) ? this.onChangeTitle : 'next';
    this.setState({
      inputphoneValue: number,
      requiredConfirm: true,
      btnTitle: btnTitle
    });
    if (this.onChangeFunction) this.onChangeFunction();
  }
  validPhoneNumber = () => {
    const {inputprefixValue, inputphoneValue, requiredConfirm} = this.state;
    this.setState({validError:''});
    //console.log('validPhoneNumber '+requiredConfirm, inputphoneValue,inputprefixValue);
    if (!inputphoneValue || !inputprefixValue) {
      this.setState({loading:false});
      if (this.onResetPhoneNumber) this.onResetPhoneNumber();
      return false;
    }else if (!requiredConfirm) {
      this.getResult();
    }else{

      const _pn = helper.patterPhoneNumberToSend(inputprefixValue, inputphoneValue);
      this.inputphone.current.disabled = true;
      callServerApi('ws/get-phone-search', 'POST', { phoneNumber : _pn })
          .then(result => {
            this.afterValidPhoneNumber(result);
      })
      .catch(error => {
          this.handleError(error.message);
      });
    }
  }

  afterValidPhoneNumber = (data) => {
    const {prefixSelected, inputphoneValue} = this.state;
    this.setState({loading:false});
    this.inputphone.current.disabled = false;
    if (data.status === 'ko') {
      this.setState({validError: data.error || '', requiredConfirm: false});
    }else{
      this.setState({btnTitle:'next', requiredConfirm: false});
      if (data && data.Items && data.Items.length > 0){
        this.sendingResultData({prefixSelected:prefixSelected, phoneNumber: inputphoneValue, providers:data.Items});
      }else{
        this.getResult();
      }
    }
  }

  /* ------------------------
  * PROVIDERS
  *------------------------*/
  getResult = () => {
    const {prefixSelected, inputphoneValue} = this.state;
    this.setState({loading:true});
    if (!prefixSelected) { this.reload(); return false; }
    callServerApi('get-providers?countryIsos='+prefixSelected.country_iso, 'GET')
      .then(result => {
        if (result.status === 'ok' && result.data && result.data.length > 0){
          this.sendingResultData({prefixSelected:prefixSelected, phoneNumber: inputphoneValue, providers:result.data});
          //this.setState({loading:false});
          } else {
            this.setState({btnTitle:this.state.btnTitleProps});
            this.reload();
            this.handleError(result.error);
        }
      })
      .catch(error => {
          this.handleError(error.message);
      });
  }

   /*
        oData = {prefixSelected, phoneNumber, providers || providerSelected}
        @Recharge, @Beneficiaries
    */
  sendingResultData = (oData) => {
    oData.phoneNumber = String(oData.phoneNumber).replace(/ /g, '');

    this.setState({loading:false,btnTitle:''});
    this.sendResult(oData);
  }

  render() {
      const { loading, placeHolder, isOpen,  validError, btnTitle, responseError,
        prefixes, prefixesFiltered, prefixSelected, inputprefixValue, inputphoneValue, inputphoneStyle,
        selectedIndex } = this.state;
      return(
          <Translation ns={['translation']}>
                {(t) => <>
                {loading && <Loading/> }
                {responseError && <ResponseAlert response={responseError} variant="danger" />}
                {!prefixSelected &&
                  <div className="phoneNumber autocomplete-dropdown">
                        <input  autoFocus
                          type="text"  ref={this.inputprefix}
                          value={inputprefixValue}
                          onChange={this.handleChangeInputPrefix}
                          onFocus={this.handleFocusInputPrefix}
                          onBlur={this.handleLostFocusInputPrefix}
                          onKeyDown={this.handleKeyDownInputPrefix}
                          placeholder={placeHolder}
                          className="autocomplete-input form-control"
                        />
                        {isOpen && prefixesFiltered && prefixesFiltered.length > 0 &&
                          <ul className="autocomplete-list" ref={this.ulRef}>
                            {prefixesFiltered.map((x, index) => (
                              <li
                                key={x.value}
                                ref={(el) => this.liRefs[index] = el}
                                className={`autocomplete-item d-flex ${selectedIndex === index ? 'selected' : ''}`}
                                onClick={() => this.setPrefix(x)}
                              >
                                {(x.img) && <img className="col thumbnail-image mini"  src={x.img} alt={x.label} />}
                                <span className='col'>{x.label}</span>
                                {(x.rlabel) && <span className='col text-right'>{x.rlabel}</span>}
                              </li>
                            ))}
                          </ul>
                        }
                  </div>
                }
                {prefixSelected && <>
                    <div className='phoneNumber input-nav-group mb-4'>
                        <Nav className="me-auto">
                            <NavDropdown
                                title={
                                    <div className='d-flex'>
                                        <div><img className="thumbnail-image mini"
                                            src={prefixSelected.img}
                                            alt={prefixSelected.label}
                                        /></div>
                                        <div>&nbsp;{prefixSelected.rlabel}</div>

                                    </div>
                                }  id="prefixselected-nav-dropdown">
                            {prefixes.map(x => (
                                <NavDropdown.Item key={x.value} className="d-flex" href="#" onClick={() => this.setPrefix(x)}>
                                    <div className='col-2 text-left'><img className='thumbnail-image mini' src={x.img} alt={x.label} /> </div>
                                    <div className='col-8'>{x.label}</div>
                                    <div className='col-2 text-right'>{x.rlabel}</div>
                                </NavDropdown.Item>
                            ))}

                            </NavDropdown>
                        </Nav>
                        <input type="text" id="phoneNumberInput" autoFocus
                          style={inputphoneStyle}
                          ref={this.inputphone}
                          value={inputphoneValue}
                          onChange={this.handleChangeInputPhone}
                          onSelect={this.handleSelectInputPhone}
                          onKeyDown={this.handleKeyDownInputPhone}
                          data-max={prefixSelected.max_length}
                          data-min={prefixSelected.min_length}
                        />
                    </div>

                    {validError && <p className='small valid-phone-error text-center'> <span className="fa fa-info-circle text-default"></span>  {validError} </p>}
                    { btnTitle && <div className='valid-phone text-center m-auto '>
                        <Button as="input" type="button" className='btn-style btn-valid col-12 col-lg-6' value={t(btnTitle)} onClick={this.validPhoneNumber} />
                    </div> }
                </> }

                </>
                  }
          </Translation>
        );
  }


  scrollToItem = (index) => {
    if (this.liRefs[index]) {
        const liElement = this.liRefs[index];
        const ulElement = this.ulRef.current;

        // Calcula el desplazamiento necesario para que el li esté visible
        const liTop = liElement.offsetTop;
        const liBottom = liElement.offsetTop + liElement.offsetHeight;
        const ulScrollTop = ulElement.scrollTop;
        const ulHeight = ulElement.offsetHeight;

        if (liTop < ulScrollTop) {
            // Desplazar hacia arriba
            ulElement.scrollTop = liTop;
        } else if (liBottom > ulScrollTop + ulHeight) {
            // Desplazar hacia abajo
            ulElement.scrollTop = liBottom - ulHeight;
        }
    }
}

}
export default autocompletePhoneNumber;
