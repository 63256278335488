import React from 'react';
import PublicBanner from '../elements/PublicBanner';
import RegisterComponent from "../../components/Auth/Register";

function Register() {
    return (
        <>
            <PublicBanner title ="register.register now"/>
            <section id="register" className="main-section pt-lg-0 home-services">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8 col-12">
                            <RegisterComponent/>
                        </div>
                    </div>
                   
                </div>

            </section>
           
        </>
    )
}
export default Register;