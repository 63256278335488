import React from 'react';
import PublicBanner from '../elements/PublicBanner';
import LoginComponent from "../../components/Auth/Login";

export default function Login() {

    return (
        <>
            <PublicBanner title ="login.let’s get you signed in"/>
            <section id="login" className="main-section pt-lg-0 home-services">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8 col-12">
                                <LoginComponent />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

