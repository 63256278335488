import React from "react";
import {useTranslation} from "react-i18next";
import {useFormatString} from "../components/Utils/formatString";
import PublicBanner from './elements/PublicBanner';

function TermsConditions() {
    const {t} = useTranslation(['translation']);
    const formatString = useFormatString();
    const paragraph1 = formatString('terms conditions.paragraph-1');
    const paragraph2 = formatString('terms conditions.paragraph-2');
    const paragraph3 = formatString('terms conditions.paragraph-3');
    const paragraph4 = formatString('terms conditions.paragraph-4');
    const paragraph5 = formatString('terms conditions.paragraph-5');
    const paragraph6 = formatString('terms conditions.paragraph-6');
    const paragraph7 = formatString('terms conditions.paragraph-7');

    return (
        <>
            <PublicBanner title="terms conditions.title"/>
          
            <section className="w3l-aboutblock1 py-3" id="privacy-policy">
                <div className="container">
                    <h3 className="font-weight-bold mb-3">
                        {t("terms conditions.conditions of use")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph1}}></p>


                    <h3 className="font-weight-bold mt-5 mb-3">
                        {t("terms conditions.products")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph2}}></p>

                    <h3 className="font-weight-bold mt-5 mb-3">
                        {t("terms conditions.purchase, delivery and right of withdrawal")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph3}}></p>

                    <h3 className="font-weight-bold mt-5 mb-3">
                        {t("terms conditions.methods of payment")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph4}}></p>

                    <h3 className="font-weight-bold mt-5 mb-3">
                        {t("terms conditions.price and taxes")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph5}}></p>

                    <h3 className="font-weight-bold mt-5 mb-3">
                        {t("terms conditions.limitation of Liability and Liability")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph6}}></p>

                    <h3 className="font-weight-bold mt-5 mb-3">
                        {t("terms conditions.your instructions")}.
                    </h3>

                    <p className="mb-5" dangerouslySetInnerHTML={{__html: paragraph7}}></p>


                </div>
            </section>

        </>
    );
}

export default TermsConditions;