import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import callServerApi from "./../Utils/apiManager";
import RequireAuth from './../Auth/RequireAuth';
import { Loading } from '../Common/Loading';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';


class PersonalInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            responseError: '',
            responseSuccess: '',
            formData : {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
            },
            errors: {},
            isEditable: false,
            isShowBtn: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    componentDidMount = () =>{
        const fnNext = (result) => {
            if (result.status ==='ok' && result.data[0]){
                this.setState(prevState => ({
                    loading: false,
                    formData: {
                        ...prevState.formData,
                        first_name: result.data && result.data[0] && result.data[0]['first_name'] ? result.data[0]['first_name'] : "",
                        last_name: result.data && result.data[0] && result.data[0]['last_name'] ? result.data[0]['last_name'] : "",
                        phone: result.data && result.data[0] && result.data[0]['phone'] ? result.data[0]['phone'] : "",
                        email: result.data && result.data[0] && result.data[0]['email'] ? result.data[0]['email'] : "",
                    },
                }));
            }else{
                this.setState({
                    message: result.error,
                    loading:false
                });
                setTimeout(() =>{ this.setState({message:''})}, 5000);
            }
        }
        callServerApi('client/profile', 'POST', {}, true)
            .then(fnNext)
            .catch(error => {
                this.setState({
                    message: error.message,
                    loading: false
                });
                setTimeout(() => { this.setState({message: ''})}, 5000);
            });
    }

    handleChange(event) {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        });
    }

    handlePhoneChange(value) {
        let d = this.state.formData;
        d.phone = (value || '').replace(/\s/g, '');
        this.setState({ formData: d });
    }

    validateForm() {
        let isValid = true;
        let errors = { ...this.state.errors };

        const validators = {
            first_name: /\S/,
            last_name: /\S/,
            phone:  /^\+\d{1,9}\s?\d{4,13}$/,
            email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
        };

        Object.keys(this.state.formData).forEach(name => {
            if(validators[name]) {
                if (!this.state.formData[name]) {
                    isValid = false;
                    errors[name] = 'form.required';
                } else if(!validators[name].test(this.state.formData[name])) {
                    isValid = false;
                    errors[name] = 'form.invalidField';
                } else {
                    errors[name] = '';
                }
            }
        });
        this.setState({ errors });
        return isValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()){
            this.setState({loading:true});
            const fnNextForm = (result) => {
                if (result.status === 'ok' && result.code === 200){
                    this.setState({
                        loading:false,
                        responseSuccess: result.message,
                        isEditable: false,
                    });
                    this.toggleEdit();
                }
                setTimeout(() =>{ this.setState({responseSuccess:''})}, 5000);
            }
            callServerApi('client/update', 'PUT', this.state.formData, true)
                .then(fnNextForm)
                .catch(error => {
                    this.setState({
                        responseError: error.data.error,
                        loading: false,
                    });
                    setTimeout(() => { this.setState({responseError: ''})}, 5000);
                });
        }
    }

    renderResponse(response, alertClass) {
        if (response) {
            return (
                <div className={alertClass} role="alert">
                    {response}
                </div>
            )
        }
    }

    toggleEdit() {
        this.setState({
            isEditable: !this.state.isEditable,
            isShowBtn: !this.state.isShowBtn
        });
    }

    render() {
        const { loading, isEditable, isShowBtn, errors, responseSuccess, responseError} = this.state;
        return (
            <Translation ns={['translation']}>
                { (t) => <div className="map-content-9">
                    {loading && <Loading/>}
                    <div className="row">
                        <div className="col-6">
                            <h3 className="font-weight-bold">{t("account.personal information")}</h3>
                        </div>
                        <div className="col-6">
                            {!isShowBtn &&
                            <button className="float-right btn btn-link"
                                    onClick={this.toggleEdit}>
                                <i className="fa fa-pencil-square-o" aria-hidden="true"></i> {t('edit')}
                            </button>
                            }
                            {isShowBtn &&
                                <button className="float-right btn btn-link"
                                        onClick={this.handleSubmit}>
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i> {t('save')}
                                </button>
                            }
                        </div>
                    </div>
                    {this.renderResponse(responseSuccess, "alert alert-success text-center")}
                    {this.renderResponse(responseError, "alert alert-danger text-center")}
                    <form className="mt-3" id="form-contact">
                        <div className="form-row">
                            <div className="col-12 col-lg-6 mb-3">
                                <label htmlFor="first_name"
                                       className="font-weight-bold">{t('account.name')}</label>
                                <Form.Control name="first_name"
                                    id="first_name"
                                    placeholder={t('register.name')}
                                    required
                                    value={this.state.formData.first_name}
                                    onChange={this.handleChange}
                                    disabled={!isEditable}
                                />
                                {errors && errors.first_name && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.first_name)} </Form.Control.Feedback> }
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="last_name"
                                       className="font-weight-bold">{t('account.last name')}</label>
                                 <Form.Control name="last_name"
                                    id="last_name"
                                    placeholder={t('register.name')}
                                    required
                                    value={this.state.formData.last_name}
                                    onChange={this.handleChange}
                                    disabled={!isEditable}
                                />
                                 {errors && errors.last_name && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.last_name)} </Form.Control.Feedback> }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="email"
                                       className="font-weight-bold">{t('account.email')}</label>
                                <Form.Control name="email"
                                        id="email"
                                        placeholder={t('register.email')}
                                        required
                                        value={this.state.formData.email}
                                        onChange={this.handleChange}
                                        disabled={!isEditable}
                                />
                                {errors && errors.email && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.email)} </Form.Control.Feedback> }

                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="phoneClient"
                                       className="font-weight-bold">{t('account.phone')}</label>
                                <PhoneInput
                                    flags={flags}
                                    name="phone"
                                    id="phoneClient"
                                    value={this.state.formData.phone}
                                    onChange={this.handlePhoneChange}
                                    className="form-control"
                                    international
                                    disabled={!isEditable}
                                />
                                {errors && errors.phone && <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(errors.phone)} </Form.Control.Feedback> }

                            </div>
                        </div>

                        <div className="form-row mt-4">
                            <div className="col-md-12">
                                <p className='h6'>{t("account.reminder of our policy")} <a
                                    href="/privacy-policy">{t("Privacy policy")}</a>
                                </p>
                            </div>
                        </div>
                    </form>

                </div>
                }
            </Translation>
        )
    }
}

export default withTranslation()(RequireAuth(PersonalInformation));