import React, { Component } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';

class CustomNavDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
        activeIndex: 0, // Índice del item seleccionado
        isOpen:false,   // Estado para controlar si el dropdown está abierto
    };
    this.id = props.id || '';
    this.title = props.title || 'form.choose an option';
    this.itemsearch = props.itemsearch;
    this.items = props.items;
    // this.items = (props.items || []).sort((a, b) =>
    //     a[this.itemsearch].localeCompare(b[this.itemsearch])
    // );
    this.itemkey = props.itemkey;
    this.itemcols = props.cols;
    this.itemRefs = []; // Array de referencias para los items del dropdown
    console.log('CustomNavDropdown > props', props);
  }
// Maneja el evento keyup para la navegación
  handleKeyUp = (event) => {
    const { activeIndex } = this.state;
    const maxIndex = this.items.length - 1;

    switch (event.key) {
      case 'ArrowDown':
        // Mover hacia abajo
        this.setState(
          {
            activeIndex: activeIndex < maxIndex ? activeIndex + 1 : 0,
          },
          () => this.focusAndScrollToActiveItem() // Foco y scroll en el item activo
        );
        break;
      case 'ArrowUp':
        // Mover hacia arriba
        this.setState(
          {
            activeIndex: activeIndex > 0 ? activeIndex - 1 : maxIndex,
          },
          () => this.focusAndScrollToActiveItem() // Foco y scroll en el item activo
        );
        break;
      case 'Enter':
        // Seleccionar item con Enter
        if (activeIndex >= 0) {
          const selectedItem = this.items[activeIndex];
          this.handleSelect(selectedItem);
        }
        break;
      default:
        // Filtrar por tecla presionada, que comience con esa letra
        const pressedKey = event.key.toUpperCase();
        const foundIndex = this.items.findIndex(
          (item) => item[this.itemsearch] && typeof item[this.itemsearch] === 'string' && item[this.itemsearch].startsWith(pressedKey)
        );
        if (foundIndex !== -1) {
          this.setState(
            { activeIndex: foundIndex },
            () => this.focusAndScrollToActiveItem() // Foco y scroll en el item activo
          );
        }
        break;
    }
  };

   // Enfoca y hace scroll hasta el item activo
   focusAndScrollToActiveItem = () => {
     const { activeIndex } = this.state;
    if (activeIndex && activeIndex >= 0 && this.itemRefs[activeIndex]) {
        const itemTop =  this.itemRefs[activeIndex].offsetTop;
        const itemBottom = this.itemRefs[activeIndex].offsetTop + this.itemRefs[activeIndex].offsetHeight;
        const containerElement = document.getElementById(this.id + '_CustomNavDropdown');
        const containerScrollTop =  containerElement.scrollTop;
        const containerHeight = containerElement.offsetHeight;
        if (itemTop < containerScrollTop) {
            containerElement.scrollTop = itemTop;
        } else if (itemBottom > containerScrollTop + containerHeight) {
            containerElement.scrollTop = itemBottom - containerHeight;
        }
        this.itemRefs[activeIndex].focus();
    }
  };

  // Maneja la selección de un item
  handleSelect = (selectedItem, index) => { console.log(selectedItem, index);
    this.setState({activeIndex: index});
    if (this.props.onSelect) {
      this.props.onSelect(selectedItem);
    }
  };

  // Maneja la apertura y cierre del NavDropdown
  handleToggle = (isOpen) => { //console.log('handleToggle');
    this.setState({ isOpen });
    // if (!isOpen) {
    //   this.setState({ activeIndex: -1 }); // Reiniciar el índice activo al cerrar
    // }
  };

//   componentWillUnmount() {
//     // Remover el event listener al desmontar el componente
//     document.removeEventListener('keyup', this.handleKeyUp);
//   }


  render() {
    const { activeIndex } = this.state;

    return (

        <div  id={this.id + '_CustomNavDropdown'} className={'custom-nav-group mb-4 ' + this.itemkey} >
            <Nav className="me-auto">
                <NavDropdown className='text-dark'
                  title={
                      <div className='d-flex'>
                          {activeIndex > -1 && this.itemcols[0] === 'logo' &&  <img style={{ maxHeight: '2rem'}} className="thumbnail-image mr-2" src={this.items[activeIndex].logo} alt={this.items[activeIndex][this.itemcols[1]]} /> }
                          {activeIndex > -1 && this.itemcols[0] !== 'logo' && <div>&nbsp;{this.items[activeIndex][this.itemcols[0]]}</div> }
                          {activeIndex > -1 && this.itemcols.length > 1 && <div>&nbsp;{this.items[activeIndex][this.itemcols[1]]}</div> }
                      </div>
                    }
                    onToggle={this.handleToggle}  // Controla el evento onToggle para saber cuándo está abierto o cerrado
                    onKeyDown={this.handleKeyUp}
                >
                {this.items.map((item, index) => (<React.Fragment key={item[this.itemkey]}>
                    <NavDropdown.Item
                        ref={(el) => (this.itemRefs[index] = el)}
                        active= {activeIndex === index}  // Marca como activo el item actual
                        onClick={() => this.handleSelect(item, index)}  // Maneja selección con click
                        >
                        {this.itemcols[0] === 'logo' &&  <img src={item.logo} alt={item[this.itemcols[1]]} /> }
                        {this.itemcols[0] !== 'logo' && <div>{item[this.itemcols[0]]}</div> }
                        {this.itemcols.length > 1 && <div>{item[this.itemcols[1]]}</div> }

                    </NavDropdown.Item>
                    </React.Fragment>
                ))}
                </NavDropdown>
            </Nav>
        </div>

    )
  }
}
export default CustomNavDropdown;
