import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';


const ResponseAlert = ({response, variant}) => {
    const [contenttAlert, setContentAlert] = useState(response);
    const [visibleAlert, setVisibleAlert] = useState(false);

    useEffect(() => {
        if (contenttAlert){
            setVisibleAlert(true)
        }
        setTimeout(() => {  closeAlert(); }, 3000);
    }, [contenttAlert, setVisibleAlert]);

    const closeAlert = () => {
        setContentAlert('');
        setVisibleAlert(false);
    };
  
    return ( 
    <>
        {visibleAlert && (<Alert variant={variant}  onClose={() => closeAlert()} dismissible>
            {contenttAlert} 
        </Alert>)  }
    </>
    );
}

export default ResponseAlert;