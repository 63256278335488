import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import env from "../../helper/env.js";

const STATUS_MAP = {
    'Pagada': { icon: "g-valid.png", tooltip: "tooltip-valid" },
    'Paid': { icon: "g-valid.png", tooltip: "tooltip-valid" },
    'Cancelada': { icon: "g-danger.png", tooltip: "tooltip-danger" },
    'Cancelled': { icon: "g-danger.png", tooltip: "tooltip-danger" },
    'Rechazada': { icon: "g-danger.png", tooltip: "tooltip-danger" },
    'Declined': { icon: "g-danger.png", tooltip: "tooltip-danger" },
    'default': { icon: "g-warning.png", tooltip: "tooltip-warning" },
};

const StatusIcon = ({ status }) => {
    const { icon, tooltip } = STATUS_MAP[status] || STATUS_MAP.default;
    return (
        <>
            <img data-tooltip-id={tooltip} src={`${env.img_basepath}icons/${icon}`} alt="check-ok"/>
            <ReactTooltip
                id={tooltip}
                place="top"
                content={status}
            />
        </>
    );
};

export default StatusIcon;