import React from 'react';
import {Translation, withTranslation} from 'react-i18next';
import callServerApi from "../Utils/apiManager";
import RequireAuth from '../Auth/RequireAuth';
import DataTable, { createTheme } from 'react-data-table-component';
import { Loading } from '../Common/Loading';
import StatusIcon from '../Common/StatusIcon';
import Nav from 'react-bootstrap/Nav';

import env from "../../helper/env.js";

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            columns :  [],
            data:[],
            errors: {},
            dashboard: !!(props.dashboard),
            pagination: (!props.dashboard),
        };
    }

    componentDidMount = () =>{
        const fnNext = (result) => {
            if (result.status ==='ok' && result.code === 200){
                const columns = result.data.columns.map((column) => ({
                    ...column,
                    selector: row => {
                        if (column.selector === 'status') {
                            return <StatusIcon status={row.status} />;
                        } else {
                            return row[column.selector];
                        }
                    },
                }))
                this.setState({
                    data : result.data.orders,
                    columns,
                    loading:false
                })
            }
        }
        callServerApi('order-history', 'POST', {onlyRecent: this.state.dashboard}, true)
            .then(fnNext)
            .catch(error => {
                this.setState({
                    loading: false,
                    errors: error
                });
            });
    }

    render() {
        const { loading, columns, data, pagination, dashboard} = this.state;
        createTheme('solarized', {
            text: {
                primary: '#268bd2',
                secondary: '#2aa198',
            },
            background: {
                default: '#e1ebc9',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#073642',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        }, 'dark');
        return (
            <Translation ns={['translation']}>
                { (t) => <div id='client_orderhistory'>
                    {loading && <Loading/>}
                    
                    <div className='row mb-5'>
                        <div className='col-12 text-center data_table'>
                            {!loading && data && data.length === 0 && <>
                                <img className="image-shadow" src={env.img_basepath +"img-desert-skyline.webp"} alt="Status"/>
                                <h4>Ohhh... {t("history.you have not made any recharges yet")}</h4>
                            </>
                            }

                            {!loading  && data && data.length > 0 && <>
                                { dashboard && <div className='d-flex justify-content-between align-items-center mb-2'>
                                    <div className='font-weight-bold'>{t("account.order history")}</div>
                                    <Nav className="justify-content-right nav">
                                        <Nav.Link href="/history">{t("all")} <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                                    </Nav>
                                </div>}
                                { !dashboard && <div className='d-flex justify-content-between align-items-center mb-2'>
                                    <Nav className="justify-content-right nav">
                                        <Nav.Link href="/account" className='d-flex align-items-center'>
                                             <div className='icon-box mr-1'><span className="fa fa-cubes"></span> </div> 
                                             <div className='mr-1'><i className="fa fa-angle-left" aria-hidden="true"></i> </div>
                                             {t("account.dashboard")}
                                        </Nav.Link>
                                    </Nav>
                                </div>}
                            
                                <DataTable
                                    theme="solarized"
                                    columns={columns}
                                    data={data}
                                    pagination={pagination}
                                />
                            </>}
                        </div>
                    </div>
                </div>
                }
            </Translation>
        )
    }
}

export default withTranslation()(RequireAuth(OrderHistory));